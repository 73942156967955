import React, { useEffect, Fragment } from "react"
import CTA from "../../general/cta"
import { scrollToElement } from "../../../../utility/utils"
import { useState } from "react"
import BlogHeader from "./blog-header"
import BlogContentSection from "./blog-content-section"
import BlogDetailsErrorPage from "./blog-details-error-page"
import { formatBlogDetails } from "./helper-utility"
import BlogMarkdownEditorSection from "../components/blog-markdown-editor-section"
import BlogRichEditorBlocksSection from "../components/blog-rich-editor-blocks-section"
import BlogVideoSection from "./blog-video-section"
import { BlogPageStyled } from "../styles/blogPageStyle"
import { BlogListCards } from "../components/blog-list-cards"
import BlogCta from "../components/blog-cta"
import { getBlogContent } from "./api-utility"
import BlogHeaderSkeleton from "./blog-header-skeleton"
const BlogDetailsPage = (props) => {
  const [state, setState] = useState({
    isLoading: true,
    hasError: false,
    relatedBlogs: [],
    blogDetails: null,
  })
  const [blogBody, setBlogBody] = useState(null)

  useEffect(() => {
    const result = props.blogDetails
    const fetchData = async () => {
      if (result?.blogTitle) {
        const data = await getBlogContent(result?.blogTitle, result?.linkedBlogs)
        if (data) {
          setState({
            ...state,
            isLoading: false,
            hasError: false,
            blogDetails: data.blogDetails,
            relatedBlogs: data.relatedBlogs,
          })
          setBlogBody(data?.blogContent)
        } else {
          setState({
            ...state,
            isLoading: false,
            hasError: true,
            blogDetails: null,
            relatedBlogs: []
          })
        }

      }
    }
    fetchData()
  }, [props])
  useEffect(() => {
    scrollToElement()
    window.addEventListener("scroll", function () {
      scrollToElement()
    })
  }, [])
  return (
    <Fragment>
      <Fragment>
        <div className="kuda-inner--heading">
          <div className="kuda-section--inner">
            {!state.isLoading ?
              <div className="flex flex-column px-0">
                {state.blogDetails && <BlogHeader {...state.blogDetails} />}
                {state.hasError ? <BlogDetailsErrorPage /> :
                  <BlogPageStyled>
                    <div className="flex flex-column px-0">
                      {blogBody && blogBody?.map((listItemContent, index) => {
                        switch (listItemContent.__component) {
                          case "section.blog-markdown-editor":
                            return <BlogMarkdownEditorSection {...listItemContent} key={index} />
                          case "section.blog-text-paragraph":
                            return <BlogContentSection {...listItemContent} key={index} />
                          case "section.blog-rich-editor-block":
                            return <BlogRichEditorBlocksSection {...listItemContent} key={index} />

                          case "section.blog-video-block":
                            return <BlogVideoSection {...listItemContent} key={index} />
                          default:
                            break;
                        }
                      })}


                      <BlogCta />

                      {state?.relatedBlogs?.length > 0 &&
                        <div className="related-blog-list kuda-ignore--container">
                          <div className="container">
                            <div className="flex flex-column blog-kuda-section--max mx-auto">
                              <div className="color-black text-center text-xlbold">Read this next</div>
                              <BlogListCards blogs={state.relatedBlogs} headingColor={'color-black'} />
                            </div>
                          </div>

                        </div>
                      }

                    </div>
                  </BlogPageStyled>
                }
              </div> :
              <BlogHeaderSkeleton />
            }
          </div>
        </div>
        <CTA />
      </Fragment>

    </Fragment>
  )
}

export default BlogDetailsPage
