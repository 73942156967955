import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import BlogDetailsPage from "../components/body/pages/blog/blog-details"
import { graphql } from 'gatsby'
import { formatPathString } from "../util/util"
const BlogDetailsPageByParam = (props) => {
  const data = props.data
  const formatedPath = formatPathString(data?.strapiBlog?.blogTitle);
  return (
    <Layout>
      <SEO
        canonical={`https://kuda.com/blog/${formatedPath}/`}
        title={data?.strapiBlog?.seoTitle}
        description={data?.strapiBlog?.seoDescription}
      />
      <BlogDetailsPage blogDetails={data.strapiBlog} />
    </Layout>
  )
}

export default BlogDetailsPageByParam

export const query = graphql`
query ($blogTitle: String) {
    strapiBlog(blogTitle: {eq: $blogTitle}) {      
      blogSubtitle
      blogTitle
      publication
      seoTitle
      seoDescription
      id
      publishedDate
      publishedAt
      publication
      linkedBlogs {
        blogHeroImage {
          url
        }
        blogTitle
        blogSubtitle
        publishedDate
        publishedAt
        publication
      }
    }
  }
`