import React from "react"
import { BlogPageStyled } from "../styles/blogPageStyle"
import Skeleton from "react-loading-skeleton"
import 'react-loading-skeleton/dist/skeleton.css'

const BlogHeaderSkeleton = () => {
  return (
    <BlogPageStyled>
        <div className="kuda-entry--inner flex align-center flex-column top--spacing--md">
          <div className="flex flex-column blog-kuda-section--max mx-auto">
            <div className="flex flex-row">
              <div>
                <Skeleton width={115} height={15} count={1} />
              </div>

              <div className="px-2">
                <Skeleton width={115} height={15} count={1} />
              </div>
            </div>
            <h1 className="blog-heading-title color-primary text-left header--bottom">
              <Skeleton width={'100%'} height={'90%'} />
            </h1>
            <h2 className="color-black text-left text-font">
              <Skeleton width={'70%'} height={'100%'} />
              <Skeleton width={'50%'} height={'100%'} />
            </h2>
          </div>
          <div className="flex flex-column blog-kuda-section--max mx-auto blog-header-image--top">
            <div className="blog-image kuda-section--100">
              <div className="kuda-section--100">
                <Skeleton width={670} height={250} containerClassName="kuda-section--100" />
              </div>
            </div>
          </div>
        </div>
    </BlogPageStyled>
  )
}

export default BlogHeaderSkeleton
