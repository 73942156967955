import React from "react"
import ReactMarkdown from 'react-markdown';
import rehypeRaw from "rehype-raw";
import styled from "styled-components";

const BlogMarkdownEditorSection = (props) => {
    const { sectionTitle: title, markdownBody: body } = props;
    return (
        <BlogMarkdownEditorSectionStyled>
            <div className="mt-0">
                <div className="flex flex-column blog-kuda-section--max mx-auto">
                <div className="flex flex-column">
                            {title && <h1 className="kuda-blog-subtitle color-black">
                                {title}
                            </h1>}
                        </div>
                        <div className="flex flex-column text-left">
                            {body && <ReactMarkdown className={'color-black markdown'} children={body} rehypePlugins={[rehypeRaw]} />
                            }
                        </div>
                </div>
            </div>

        </BlogMarkdownEditorSectionStyled>
      )
}

export default BlogMarkdownEditorSection;

const BlogMarkdownEditorSectionStyled = styled.div`
    .markdown h2,
    .markdown p, .markdown ol, .markdown ul {
        padding-top: 20px;
        padding-bottom: 10px
    }
    .markdown p strong {
        font-weight: 800
    }
    h1, h2, h3, h4, h5, h6,
    .markdown strong {
        font-weight: 800
    }
    .markdown ol, ul {
        list-style: auto;
        font-size: 18px;
        line-height: 1.6;

        li{
            margin-left: 15px;
        }
    }

    .markdown p{
        font-size: 18px;
        line-height: 1.6;
    }

    @media only screen and (max-width: 767px){
        .markdown p, .markdown ul>li, .markdown ol>li{
            font-size: 16px;
        }

    }

`