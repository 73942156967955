import React from "react"
import { BlocksRenderer } from "@strapi/blocks-react-renderer";
import styled from "styled-components";
const BlogRichEditorBlocksSection = (props) => {
    const { title, sectionBody } = props;
    return (
        <BlogEditorBlocksStyled>
            <div className="mt-0">
                <div className="kuda-section--inner flex justify-between align-center">
                    <div className="kuda-section--100">
                        <div className="flex flex-column blog-kuda-section--max mx-auto">
                            {title && <h1 className="kuda-blog-subtitle color-black">
                                {title}
                            </h1>}
                        </div>
                        <div className="flex flex-column blog-kuda-section--max mx-auto text-left">
                            {sectionBody && <BlocksRenderer content={sectionBody}
                                blocks={{
                                    heading: ({ children, level }) => {
                                        const CustomTag = `h${level}`;
                                        return <CustomTag className="color-black mb-3">{children}</CustomTag>
                                    },
                                    paragraph: ({ children }) => <span className="mb-2 color-black kuda-blog-section text-font text-left">{children}</span>,
                                    link: ({ children, url }) => <a className="link-decoration color-primary text-font text-left" href={url}>{children}</a>,
                                    list: ({ children }) => {
                                        return <span className="px-3 color-black mb-2 text-left editor-list text-font text-left">{children}</span>
                                    },
                                    image: ({ image }) => {
                                        return <div className="kuda-section--100 blog-image">
                                            <img
                                                src={`${image?.url}`}
                                                width={image?.width}
                                                height={image?.height}
                                                alt={`${image?.alternativeText ? image.alternativeText : 'Kuda blog image'}`} />
                                            {image?.caption && <div className="flex flex-row color-default mx-auto text-center p-1 p-md-4">
                                                {`Image caption: ${image?.caption}`}
                                            </div>
                                            }
                                        </div>
                                    }
                                }}
                                modifiers={{
                                    bold: ({ children }) => <div className="text-bold text-font">{children}</div>,
                                    italic: ({ children }) => <span className="text-italic text-font">{children}</span>,
                                }}
                            />}
                        </div>
                    </div>
                </div>
            </div>

        </BlogEditorBlocksStyled>
    )
}

export const BlogEditorBlocksStyled = styled.div`
    .editor-list {
        li:not(:last-child) {
            margin-bottom: 1.14em;
        }
    }

    .text-font{
        font-size: 18px;
    }

    @media only screen and (max-width: 767px){
        font-size: 16px;
    }
    
@media only screen and (min-width: 768px) and (max-width: 1024px){    
        p img,
      .blog-image img,
      .blog-section-image img {
        height: 100%;
        width: 100%;  
        object-fit: contain;
      }           
      
`

export default BlogRichEditorBlocksSection;
