import React from "react"
const BlogContentSection = props => {
  const { sectionDescription, sectionTitle, sectionImage } = props
  const imageData = sectionImage?.data
  const image = imageData?.attributes
  return (
    <div className="kuda-section mt-0">
      <div className="kuda-section--inner flex justify-between align-center">
      <div className="flex flex-column blog-kuda-section--max mx-auto">
            {sectionTitle && <h1 className="kuda-blog-subtitle color-black">
              {sectionTitle}
            </h1>}
          </div>
          <div className="flex flex-column blog-kuda-section--max mx-auto">
            {image && (
              <div className="flex justify-center mx-auto title-bottom--spacing">
                <div  className="blog-image">
                  <img
                    src={`${process.env.GATSBY_BLOGCMS_URL}${image?.url}`}
                    alt={`${image.alternativeText
                        ? image.alternativeText
                        : "Kuda blog image"
                      }`}
                  />
                  {image?.caption && (
                    <div className="flex flex-row color-default mx-auto text-center p-1 p-md-4">
                      {`Image caption: ${image?.caption}`}
                    </div>
                  )}
                </div>
              </div>
            )}

            <p className="color-black text-left text-md">{sectionDescription}</p>
          </div>
      </div>
    </div>
  )
}

export default BlogContentSection
